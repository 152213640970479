import { render, staticRenderFns } from "./CityField.vue?vue&type=template&id=080ed216&scoped=true"
import script from "./CityField.vue?vue&type=script&lang=js"
export * from "./CityField.vue?vue&type=script&lang=js"
import style0 from "./CityField.vue?vue&type=style&index=0&id=080ed216&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080ed216",
  null
  
)

export default component.exports