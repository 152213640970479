<template>
  <div v-if="v.address">
    <div class="location-fields columns">

      <div class="column is-6">
        <div class="field">
          <label class="label">{{ locationLabelsText.department }} *</label>
          <div class="select control">
            <select v-model.trim="v.address.department.$model">
              <option v-for="(department, index) in departments" :key="index" :value="department">
                {{ department.value }}
              </option>
            </select>
          </div>
          <div v-if="v.address.department.$dirty">
            <p v-if="!v.address.department.required" class="help is-danger">
              {{ locationLabelsText.departmentError }}
            </p>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <div class="field">
          <label class="label">{{ locationLabelsText.city }} *</label>
          <div class="select control">
            <select v-model.trim="v.address.city.$model" :disabled="!v.address.department.$model">
              <option v-for="(city, index) in cities" :key="index" :value="city">
                <span v-if="applicationVersion === VERSIONS.PANAMA">{{`${city.parentValue}, ${city.value}`}}</span>
                <span v-else>{{ city.value }}</span>
              </option>
            </select>
          </div>
          <div v-if="v.address.city.$dirty">
            <p v-if="!v.address.city.required" class="help is-danger">
              {{ locationLabelsText.cityError }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { get } from 'vuex-pathify'
import { VERSIONS } from '@/utils/constants'

export default {
  name: 'CityField',
  inject: ['v'],
  data() {
    return {
      VERSIONS
    }
  },
  computed: {
    applicationVersion: get('shop@shop.config.applicationVersion'),
    cities: get('config@cities'),
    ...mapState('config', ['departments']),
    locationLabelsText() {
      const {
        ARGENTINA,
        CHILE,
        COLOMBIA,
        REPUBLICA_DOMINICANA,
        COSTA_RICA,
        PANAMA,
        PERU
      } = VERSIONS

      if (this.applicationVersion === ARGENTINA) {
        return {
          department: 'Provincia',
          city: 'Ciudad',
          departmentError: 'Selecciona una provincia',
          cityError: 'Selecciona una ciudad'
        }
      }

      if (this.applicationVersion === CHILE) {
        return {
          department: 'Region',
          city: 'Ciudad',
          departmentError: 'Selecciona una region',
          cityError: 'Selecciona una ciudad'
        }
      }

      if (this.applicationVersion === COLOMBIA) {
        return {
          department: 'Departamento',
          city: 'Ciudad',
          departmentError: 'Selecciona un departamento',
          cityError: 'Selecciona una ciudad'
        }
      }

      if (this.applicationVersion === REPUBLICA_DOMINICANA) {
        return {
          department: 'Provincia',
          city: 'Municipio',
          departmentError: 'Selecciona una provincia',
          cityError: 'Selecciona un municipio'
        }
      }

      if (this.applicationVersion === COSTA_RICA) {
        return {
          department: 'Provincia',
          city: 'Distrito',
          departmentError: 'Selecciona una provincia',
          cityError: 'Selecciona un distrito'
        }
      }

      if (this.applicationVersion === PANAMA) {
        return {
          department: 'Provincia',
          city: 'Distrito / Corregimiento',
          departmentError: 'Selecciona una provincia',
          cityError: 'Selecciona un distrito / corregimiento'
        }
      }

      if (this.applicationVersion === PERU) {
        return {
          department: 'Departamento',
          city: 'Provincia',
          departmentError: 'Selecciona un departamento',
          cityError: 'Selecciona una provincia'
        }
      }

      return {
        city: 'Ciudad / Departamento'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
}

.location-fields {
  margin-bottom: 5px;
}
</style>
